import { createAction, handleActions } from 'redux-actions';

const initialState = {};

export const updateUser = createAction(
  'UPDATE_USER',
  (username, password) => {
    return { username, password };
  }
);

export default handleActions({
  [updateUser]: (state, { payload }) => {
    state = payload;
    return state;
  },
}, initialState);
