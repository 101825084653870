import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { renderToString } from 'react-dom/server';
import { Provider } from 'react-redux';
import { StaticRouter } from 'react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import template from './index.ejs';
import configureStore from './store/configure';
import Root from './components/Root/Root';
import i18n from './i18n';
import getUrlParams from './utils/getUrlParams';
import { broadcastInteraction, listenForInteraction } from './utils/broadcastInteraction';
import { listenForLoginComplete } from './utils/broadcastLoginComplete';

function dispatchInteractionState(store, { clientId, interactionUid, loginHint }) {
  store.dispatch({
    type: 'SET_INTERACTION_UID',
    payload: { clientId, interactionUid },
  });

  store.dispatch({
    type: 'SET_LOGIN_HINT',
    payload: { loginHint },
  });

  store.dispatch({
    type: 'SET_OIDC_CLIENT_ID',
    payload: { oidcClientId: clientId },
  });
}

function setInitialOidcState(store, history) {
  const { client_id, interactionUid, login_hint } = getUrlParams();

  dispatchInteractionState(store, { clientId: client_id, interactionUid, loginHint: login_hint });

  listenForInteraction(({ client_id: newClientId, interactionUid: newInteractionId }) => {
    if (newClientId !== client_id) {
      return;
    }

    dispatchInteractionState(store, {
      clientId: client_id,
      interactionUid: newInteractionId,
      loginHint: login_hint,
    });
  });
  broadcastInteraction({ client_id, interactionUid });

  listenForLoginComplete(history);
}

if (typeof document !== 'undefined') {
  const history = createBrowserHistory();
  const store = configureStore(history);

  setInitialOidcState(store, history);

  ReactDOM.render(
    <Provider store={ store }>
      <ConnectedRouter history={ history }>
        <I18nextProvider i18n={ i18n }>
          <Root />
        </I18nextProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
}

const addBasePath = (filename) => `${window.baseName}${filename}`;

export default ({ path, webpackStats }) => {
  const history = createMemoryHistory();
  const store = configureStore(history);
  const assetFilenames = Object.keys(webpackStats.compilation.assets);
  const context = {};

  const html = template({
    htmlWebpackPlugin: {
      options: {
        stylesheet: addBasePath(assetFilenames.find(filename => filename.includes('.css'))),
        script: addBasePath(assetFilenames.find(filename => filename.includes('.js'))),
        html: renderToString(
          <Provider store={ store }>
            <StaticRouter context={ context } location={ path }>
              <I18nextProvider i18n={ i18n }>
                <Root />
              </I18nextProvider>
            </StaticRouter>
          </Provider>
        ),
      },
    },
  });

  setInitialOidcState(store, history);

  return html;
};
