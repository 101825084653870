import { push } from 'connected-react-router';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import apiForgot from '../api/forgot';
import { forgotFailed, forgotRequested, forgotSuccess } from '../store/forgotPassword';
import { TOKEN_EXPIRY_ERROR_CODE } from '../constants/apiErrorCodes';
import { notificationsAddNotification } from '../store/notifications';
import { clearLoginErrors, interactionUidSelector } from '../store/login';
import { getRedirectUrlWithInteraction } from '../utils/getRedirectUrl';

export function* watchForgotRequested() {
  yield takeEvery(forgotRequested, forgot);
}

export function* forgot({ payload }) {
  const { email } = payload;
  try {
    yield call(apiForgot, email);
    yield put(forgotSuccess());
    yield put(notificationsAddNotification({
      type: 'success',
      message: 'successful-password-reset-requested',
    }));
    yield put(clearLoginErrors());

    const interactionUid = yield select(interactionUidSelector);

    yield put(push(getRedirectUrlWithInteraction('/', interactionUid)));
  } catch ({ code, message }) {
    if (code === TOKEN_EXPIRY_ERROR_CODE) {
      yield put(push(`/reset/expired?email=${ email }`));
    }
    yield put(forgotFailed({ message }));
  }
}
