import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ButtonGroup,
  Form,
  Grid,
  GridCell,
  Heading,
  Paragraph,
  ProgressButton,
  Strong,
  TextInput,
} from '@brandwatch/axiom-components';
import Layout from '../Layout/Layout';
import Link from '../Link/Link';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';
import axiomAtIds from '@brandwatch/axiom-automation-testing/ids';
import {
  INTERACTION_EXPIRED,
  PASSWORD_EXPIRED_ERROR_CODE,
  RATE_LIMITED_ERROR_CODE,
} from '../../constants/apiErrorCodes';

import SpringChain from '../../utils/SpringChain';
import { getSuccessRedirectUrl, getInteractionRestartRedirectUrl } from '../../utils/getRedirectUrl';
import { broadcastLoginComplete } from '../../utils/broadcastLoginComplete';
import { emailRegex } from '../../constants/patterns';

export default class Login extends Component {
  static propTypes = {
    hasFailed: PropTypes.bool.isRequired,
    history: PropTypes.object,
    interactionUid: PropTypes.string,
    isSubmitting: PropTypes.bool.isRequired,
    loginHint: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    response: PropTypes.object,
    t: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      interactionUid,
      loginHint,
    } = this.props;
    const searchParamsUsername = emailRegex.test(loginHint) && loginHint;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      username: searchParamsUsername || '',
      password: '',
    };

    if (!interactionUid) {
      console.warn('No interaction. Restarting login flow.');

      return window.location.replace(
        getInteractionRestartRedirectUrl()
      );
    }
  }

  componentDidUpdate() {
    const {
      history,
      interactionUid,
      response,
      updateUser,
    } = this.props;

    if (response) {

      if (response.code) {
        if (response.code === RATE_LIMITED_ERROR_CODE) {
          return history.push('/restricted');
        }
        if (response.code === PASSWORD_EXPIRED_ERROR_CODE) {
          updateUser(this.state.username, this.state.password);

          return history.push('/expired');
        }
        if (response.code === INTERACTION_EXPIRED) {
          console.warn('Interaction expired. Restarting login flow.');

          return window.location.replace(
            getInteractionRestartRedirectUrl()
          );
        }
      }

      const { twoFactorAuthConfigured, needsTwoFactorAuth, returnTo } = response;

      if (needsTwoFactorAuth) {
        if (twoFactorAuthConfigured) {
          const queryString = `?${new URLSearchParams({ interactionUid })}`;

          return history.push(
            `/multi-factor${queryString}`
          );
        } else {
          return history.push('/multi-factor/setup');
        }
      }

      const loginError = (response.message || response.code);
      if (!loginError) {
        broadcastLoginComplete();

        window.location.replace(
          getSuccessRedirectUrl(returnTo)
        );
      }

    }
  }

  handleSubmit(event, validationError) {
    const { onSubmit, interactionUid } = this.props;
    const { username, password } = this.state;

    event.preventDefault();
    this.setState({ validationError });

    if (!validationError) {
      onSubmit({ username, password, interactionUid });
    }
  }

  render() {
    const { hasFailed, t, isSubmitting } = this.props;
    const { validationError, username, password } = this.state;

    return (
      <Layout width="20.125rem">

        <Form
            onSubmit={ this.handleSubmit }
            requiredError={ t('validation-no-email-password') }
            name="login">
          <SpringChain>
            <BrandwatchLogo spring-space="x8" />

            <Heading textSize="display1" spring-space="x3">
              <Strong>{ t('login-title') }<br />{ t('login-title-2') }</Strong>
            </Heading>

            <Heading textColor="subtle" textSize="large" spring-space="x3">
              { t('login-subtitle') }
            </Heading>

            <TextInput
                data-ax-at={ axiomAtIds.Login.username }
                onChange={ e => this.setState({ username: e.target.value.trim() }) }
                placeholder={ t('field-email-label') }
                required
                size="large"
                value={ username }
                name="email" />

            <TextInput
                data-ax-at={ axiomAtIds.Login.password }
                onChange={ e => this.setState({ password: e.target.value }) }
                placeholder={ t('field-password-label') }
                required
                size="large"
                type="password"
                value={ password }
                name="password" />

            <Paragraph textSize="small">
              { 'By signing in, you agree to our ' }
              <Link href={ window.appConfig.PRIVACY_POLICY_URL }>
                privacy policy
              </Link>
              { '.' }
            </Paragraph>

            <Grid verticalAlign="middle">
              <GridCell shrink>
                <ButtonGroup>
                  <ProgressButton
                      data-ax-at={ axiomAtIds.Login.submit }
                      disabled={ isSubmitting }
                      full="small"
                      isInProgress={ isSubmitting }
                      size="large"
                      type="submit">
                    { t('login-button') }
                  </ProgressButton>
                </ButtonGroup>
              </GridCell>

              <GridCell>
                <Paragraph
                    textCenter
                    textRight="small"
                    textSize="large">
                  <Link data-ax-at={ axiomAtIds.Login.forgot } to="/forgot">
                    { t('forgot-password') }
                  </Link>
                </Paragraph>
              </GridCell>
            </Grid>
          </SpringChain>
          { (hasFailed || validationError) && (
            <Paragraph
                space="x4"
                textCenter
                textColor="error"
                textLeft="small">
              <Strong data-ax-at={ axiomAtIds.Login.error }>
                { validationError || t('login-error-fallback') }
              </Strong>
            </Paragraph>
          ) }
        </Form>
      </Layout>
    );
  }
}
