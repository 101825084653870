import handleApiResponse from '../utils/handleApiResponse';

export default ({ email, password }) => {
  const url = new URL(`${window.appConfig.BW_DOMAIN}/oauth/token`);

  return fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    body: new URLSearchParams({
      username: email,
      password,
      grant_type: 'password',
      client_id: 'donny-auth-client',
    }),
  }).then(handleApiResponse);
};
