import { call, put, takeEvery } from 'redux-saga/effects';
import apiLogin from '../api/login';
import { loginFailed, loginRequested, loginSuccess } from '../store/login';

export function* watchLoginRequested() {
  yield takeEvery(loginRequested, login);
}

export function* login({ payload }) {
  try {
    const response = yield call(apiLogin, payload);
    yield put(loginSuccess(response));
  } catch ({ message, code }) {
    yield put(loginFailed({ message, code }));
  }
}
