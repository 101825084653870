import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Heading, Link, Paragraph, Strong } from '@brandwatch/axiom-components';
import SpringChain from '../../utils/SpringChain';
import atIds from '../../../at_ids';
import Layout from '../Layout/Layout';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';
import getUrlParams from '../../utils/getUrlParams';

export default class SignUpExpired extends Component {
  static propTypes = {
    onRequestInvitation: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  handleRequestInvitation() {
    const searchParams = getUrlParams();

    this.props.onRequestInvitation({
      email: searchParams.email,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Layout width="32rem">
        <SpringChain>
          <BrandwatchLogo spring-space="x8" />

          <Heading space="x4" textSize="display1">
            <Strong>{ t('sorry') }</Strong>
          </Heading>
          <Heading
              space="x4"
              textColor="subtle"
              textSize="headline">{ t('invitation-not-found') }</Heading>
          <Paragraph textColor="subtle">{ t('invitation-cancelled') }</Paragraph>
          <Paragraph textSize="large">
            <Link
                data-ah-at={ atIds.SignUpExpired.resend }
                onClick={ () => this.handleRequestInvitation() }>
              { t('invitation-request') }
            </Link>
          </Paragraph>
        </SpringChain>
      </Layout>
    );
  }
}
