import { push } from 'connected-react-router';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import apiReset from '../api/reset';
import { resetFailed, resetRequested, resetSuccess } from '../store/resetPassword';
import { notificationsAddNotification } from '../store/notifications';

export function* watchResetRequested() {
  yield takeEvery(resetRequested, reset);
}

export function* reset({ payload }) {
  try {
    const { email, password, token } = payload;
    yield call(apiReset, email, password, token);
    yield put(resetSuccess());
    yield put(notificationsAddNotification({
      type: 'success',
      message: 'successful-password-reset',
    }));
    // Wait 3s before redirecting to allow notification to show.
    yield delay(3000);
    yield put(push('/'));
  } catch ({ message }) {
    yield put(resetFailed({ message }));
  }
}
