import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { signUpRequested } from '../../store/signUp';
import SignUp from './SignUp';

const mapStateToProps = ({ signUp }) => ({
  errorMessage: signUp.errorMessage,
  hasFailed: signUp.hasFailed,
  isSubmitting: signUp.isSubmitting,
});

const mapDispatchToProps = {
  onSubmit: signUpRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SignUp));

