import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { forgotRequested } from '../../store/forgotPassword';
import ForgotPassword from './ForgotPassword';

const mapStateToProps = ({ forgotPassword, login }) => ({
  errorMessage: forgotPassword.errorMessage,
  hasFailed: forgotPassword.hasFailed,
  isSubmitting: forgotPassword.isSubmitting,
  interactionUid: login.interactionUid,
});

const mapDispatchToProps = {
  onSubmit: forgotRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ForgotPassword));
