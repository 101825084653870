import handleApiResponse from '../utils/handleApiResponse';
import loginFetch from '../utils/loginFetch';

export default () => {
  return loginFetch('/auth/v1/sendcode', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }).then(handleApiResponse);
};
