import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ButtonGroup,
  ButtonIcon,
  Form,
  Grid,
  GridCell,
  Heading,
  Paragraph,
  ProgressButton,
  Strong,
  TextInput,
} from '@brandwatch/axiom-components';
import SpringChain from '../../utils/SpringChain';
import { getRedirectUrlWithInteraction } from '../../utils/getRedirectUrl';
import Layout from '../Layout/Layout';
import Link from '../Link/Link';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';
import atIds from '../../../at_ids';

export default class ForgotPassword extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    hasFailed: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    interactionUid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { email: '' };
  }

  handleSubmit(event, validationError) {
    const { onSubmit } = this.props;
    const { email } = this.state;

    event.preventDefault();
    this.setState({ validationError });

    if (!validationError) {
      onSubmit({ email });
    }
  }

  render() {
    const { email, validationError } = this.state;
    const {
      errorMessage,
      hasFailed,
      interactionUid,
      isSubmitting,
      t,
    } = this.props;

    const backToHomeUrl = getRedirectUrlWithInteraction('/', interactionUid);

    return (
      <Layout width="28rem">
        <Form
            onSubmit={ this.handleSubmit }
            requiredError={ t('validation-no-email') }>
          <SpringChain>
            <BrandwatchLogo spring-space="x8" />

            <Heading textSize="display1">
              <Strong>{ t('forgot-password') }</Strong>
            </Heading>

            <Paragraph>{ t('forgot-help') }</Paragraph>

            <TextInput
                data-ah-at={ atIds.ForgotPassword.email }
                onChange={ e => this.setState({ email: e.target.value }) }
                placeholder={ t('field-email-label') }
                required
                size="large"
                value={ email } />

            <Grid verticalAlign="middle">
              <GridCell shrink>
                <ButtonGroup>
                  <ProgressButton
                      data-ah-at={ atIds.ForgotPassword.submit }
                      disabled={ isSubmitting }
                      full="small"
                      isInProgress={ isSubmitting }
                      size="large"
                      type="submit">
                    { t('reset-link') } <ButtonIcon name="chevron-right" />
                  </ProgressButton>
                </ButtonGroup>
              </GridCell>

              <GridCell>
                <Paragraph
                    textCenter
                    textRight="small"
                    textSize="large">
                  <Link
                    data-ah-at={ atIds.ForgotPassword.login }
                    to={ backToHomeUrl }
                  >
                    { t('back-to-login') }
                  </Link>
                </Paragraph>
              </GridCell>
            </Grid>
          </SpringChain>
          { (hasFailed || validationError) && (
            <Paragraph
                space="x4"
                textCenter
                textColor="error"
                textLeft="small">
              <Strong data-ah-at={ atIds.ForgotPassword.error }>
                { validationError || errorMessage || t('forgot-error-fallback') }
              </Strong>
            </Paragraph>
          ) }
        </Form>
      </Layout>

    );
  }
}
