import React from 'react';
import { Heading, Link, Paragraph, Strong } from "@brandwatch/axiom-components";
import SpringChain from '../../utils/SpringChain';
import BackgroundPage from '../BackgroundPage/BackgroundPage';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';

const contactUsLocation="mailto:support@brandwatch.com"
const homeLocation='/'
const theme = 'day';

const StatusError = () => {
  return (
    <BackgroundPage theme={theme} data-ah-at={"atIds.StatusError"}>
      <div className="ax-status-message ax-text--align-center">
        <SpringChain>
          <BrandwatchLogo spring-space="x8" />
          <Heading textSize="display1">
            <Strong>Ooops!</Strong>
          </Heading>
          <Heading textSize="headline">
            Something went wrong
          </Heading>
          <Paragraph>
            Visit the{" "}
            <Link href={homeLocation}>home page</Link>{" "}
            or{" "}
            <Link href={contactUsLocation}>
              contact us
            </Link>{" "}
            about the problem.
          </Paragraph>
        </SpringChain>
      </div>
    </BackgroundPage>
  )
}

export default StatusError;
