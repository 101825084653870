import handleApiResponse from '../utils/handleApiResponse';

export default ({username, password, interactionUid}) =>
  fetch(`/oidc/interaction/${interactionUid}/login`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({username, password})
  }).then(handleApiResponse);
