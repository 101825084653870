import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { enable2faRequested } from '../../store/enable2fa';
import { generateCodeKeyRequested } from '../../store/generateCodeKey';
import TwoFactorSetup from './TwoFactorSetup';

const mapStateToProps = ({ enable2fa, generateCodeKey, login }) => {
  return {
    enable2fa_errorMessage: enable2fa.errorMessage,
    enable2fa_hasFailed: enable2fa.hasFailed,
    enable2fa_isSubmitting: enable2fa.isSubmitting,
    enable2fa_response: enable2fa.response,
    generateCodeKey_errorMessage: generateCodeKey.errorMessage,
    generateCodeKey_hasFailed: generateCodeKey.hasFailed,
    generateCodeKey_hasSucceeded: generateCodeKey.hasSucceeded,
    generateCodeKey_isSubmitting: generateCodeKey.isSubmitting,
    generateCodeKey_response: generateCodeKey.response,
    interactionUid: login.interactionUid,
  };
};

const mapDispatchToProps = {
  generateCodeKey: generateCodeKeyRequested,
  onSubmit: enable2faRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TwoFactorSetup));
