import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  ButtonGroup,
  ButtonIcon,
  Form,
  Heading,
  Paragraph,
  ProgressButton,
  Strong,
  TextInput,
} from '@brandwatch/axiom-components';
import {
  ConfirmPasswordInput,
  NewPasswordInput,
} from '@brandwatch/axiom-composites';
import SpringChain from '../../utils/SpringChain';
import Layout from '../Layout/Layout';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';
import atIds from '../../../at_ids';
import axiomAtIds from '@brandwatch/axiom-automation-testing/ids';

import { SAME_PASSWORD_ERROR_MSG } from '../../constants/apiErrorCodes';

export default class ChangePassword extends Component {
  static propTypes = {
    hasFailed: PropTypes.bool,
    isSubmitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    response: PropTypes.object,
    t: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      username: props.user.username || '',
      oldPassword: props.user.password || '',
      newPassword: '',
      confirmPassword: '',
      userPrefilled: props.user.username && props.user.password,
    };
  }

  handleSubmit(e, validationError) {
    const { onSubmit } = this.props;
    const { oldPassword, newPassword, username } = this.state;

    e.preventDefault();
    this.setState({ validationError });

    if (!validationError) {
      onSubmit({
        oldPassword,
        newPassword,
        username,
      });
    }
  }

  render() {
    const { hasFailed, isSubmitting, response, t } = this.props;
    const {
      username,
      oldPassword,
      newPassword,
      confirmPassword,
      validationError,
      userPrefilled,
    } = this.state;

    let errorMessage = validationError;

    if (response && response.message === SAME_PASSWORD_ERROR_MSG) {
      errorMessage = 'Your new password should be different from the current one.';
    }

    return (
      <Layout width="28rem">
        <Form
            onSubmit={ this.handleSubmit }
            requiredError={ t('validation-all-fields') }>

          <SpringChain>
            <BrandwatchLogo spring-space="x8" />

            <Heading textSize="display1">
              <Strong>{ t('change-password') }</Strong>
            </Heading>

            { (!userPrefilled) && (
              <Fragment>
                <TextInput
                    data-ax-at={ axiomAtIds.Login.username }
                    label={ t('field-email-label') }
                    onChange={ e => this.setState({ username: e.target.value.trim() }) }
                    required
                    size="large"
                    value={ username } />

                <TextInput
                    data-ax-at={ axiomAtIds.Login.password }
                    label={ t('field-old-password-label') }
                    onChange={ e => this.setState({ oldPassword: e.target.value }) }
                    required
                    size="large"
                    type="password"
                    value={ oldPassword } />
                </Fragment>
            ) }

            <NewPasswordInput
                data-ah-at={ atIds.ResetPassword.password }
                onChange={ (e) => this.setState({ newPassword: e.target.value }) }
                required
                size="large"
                value={ newPassword } />

            <ConfirmPasswordInput
                data-ah-at={ atIds.ResetPassword.confirmPassword }
                onChange={ (e) => this.setState({ confirmPassword: e.target.value }) }
                passwordValue={ newPassword }
                size="large"
                value={ confirmPassword } />

            <ButtonGroup>
              <ProgressButton
                  data-ah-at={ atIds.ResetPassword.submit }
                  disabled={ isSubmitting }
                  full="small"
                  isInProgress={ isSubmitting }
                  size="large"
                  type="submit">
                { t('change-password-button') } <ButtonIcon name="chevron-right" />
              </ProgressButton>
            </ButtonGroup>
          </SpringChain>

          { ( errorMessage || hasFailed ) && (
            <Paragraph
                space="x4"
                textCenter
                textColor="error"
                textLeft="small">
              <Strong data-ah-at={ atIds.ResetPassword.error }>
                { errorMessage || t('change-error-fallback') }
              </Strong>
            </Paragraph>
          ) }

        </Form>
      </Layout>
    );
  }
}
