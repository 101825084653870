import { call, put, takeLatest } from 'redux-saga/effects';
import apiVerifyCode from '../api/verifyCode';
import { verifyCodeFailed, verifyCodeRequested, verifyCodeSuccess } from '../store/verifyCode';

export function* watchVerifyCodeRequested() {
  yield takeLatest(verifyCodeRequested, verifyCode);
}

export function* verifyCode({ payload }) {
  try {
    const response = yield call(apiVerifyCode, payload);
    yield put(verifyCodeSuccess(response));
  } catch ({ message }) {
    yield put(verifyCodeFailed({ message }));
  }
}
