import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkAxiom } from '@brandwatch/axiom-components';

export default class Link extends Component {
  static propTypes = {
    to: PropTypes.string,
  }

  render() {
    const { to, ...rest } = this.props;

    return to
      ? <LinkAxiom { ...rest } Component={ LinkRouter } to={ to } />
      : <LinkAxiom { ...rest } />;
  }
}
