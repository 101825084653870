import handleApiResponse from '../utils/handleApiResponse';

export default ({ email, password, token }) =>
  fetch(`${window.appConfig.BW_DOMAIN}/user/resetPassword`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      email,
      newPassword: password,
      token,
    }),
  }).then(handleApiResponse);
