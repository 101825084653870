import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { verifyCodeRequested } from '../../store/verifyCode';
import { sendCodeRequested } from '../../store/sendCode';
import TwoFactor from './TwoFactor';

const mapStateToProps = ({ verifyCode, sendCode, login }) => ({
  verify_errorMessage: verifyCode.errorMessage,
  verify_hasFailed: verifyCode.hasFailed,
  verify_isSubmitting: verifyCode.isSubmitting,
  verify_response: verifyCode.response,
  send_errorMessage: sendCode.errorMessage,
  send_hasFailed: sendCode.hasFailed,
  send_hasSucceeded: sendCode.hasSucceeded,
  send_isSubmitting: sendCode.isSubmitting,
  send_response: sendCode.response,
  interactionUid: login.interactionUid,
});

const mapDispatchToProps = {
  onSendCode: sendCodeRequested,
  onSubmit: verifyCodeRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TwoFactor));
