import { colors } from '@brandwatch/axiom-materials';

const apps = [
  { id: 'default',
    name: 'Brandwatch',
    palette: {
      base: colors.uiWhiteNoiseDark,
      accent: colors.productColors['terra-form'],
      intersect: colors.hooloovoo,
    },
  },
  { id: 'qriously',
    name: 'Qriously',
    palette: {
      base: colors.uiWhiteNoiseDark,
      accent: colors.productColors['future-shock'],
      intersect: colors.productColors['new-horizon'],
    },
  },
  { id: 'analytics',
    name: 'Brandwatch',
    palette: {
      base: colors.uiWhiteNoiseDark,
      accent: colors.productColors['sun-maker'],
      intersect: colors.productColors['terra-form'],
    },
  },
  { id: 'vizia',
    name: 'Vizia',
    palette: {
      base: colors.uiWhiteNoiseDark,
      accent: colors.productColors['serene-sea'],
      intersect: colors.productColors['paradise-lost'],
    },
  },
  { id: 'audiences',
    name: 'Audiences',
    palette: {
      base: colors.uiWhiteNoiseDark,
      accent: colors.productColors['new-horizon'],
      intersect: colors.productColors['blast-off'],
    },
  },
];

export default apps;
