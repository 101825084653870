import { createAction, handleActions } from 'redux-actions';

const initialState = {
  errorMessage: null,
  hasFailed: false,
  isSubmitting: false,
};

export const resetRequested = createAction('RESET_REQUESTED', null,
  ({ email }) => ({ mixpanel: { eventName: 'reset_password:requested', props: { email } } }));

export const resetFailed = createAction('RESET_FAILED', null,
  ({ message }) => ({ mixpanel: { eventName: 'reset_password:failed', props: { message } } }));

export const resetSuccess = createAction('RESET_SUCCESS', null,
  () => ({ mixpanel: { eventName: 'forgot_password:success' } }));

export default handleActions({
  [resetRequested]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    isSubmitting: true,
  }),
  [resetFailed]: (state, { payload }) => ({
    ...state,
    errorMessage: payload.message,
    hasFailed: true,
    isSubmitting: false,
  }),
  [resetSuccess]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    isSubmitting: false,
  }),
}, initialState);
