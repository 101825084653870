import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ChangePassword from './ChangePassword';
import { changeRequested } from '../../store/changePassword';

const mapStateToProps = ({ changePassword, user }) => ({
  errorMessage: changePassword.errorMessage,
  hasFailed: changePassword.hasFailed,
  isSubmitting: changePassword.isSubmitting,
  response: changePassword.response,
  user,
});

const mapDispatchToProps = {
  onSubmit: changeRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ChangePassword));
