import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SignUpConfirmation from './SignUpConfirmation';


const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SignUpConfirmation));
