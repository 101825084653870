import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Heading, Paragraph, Strong } from '@brandwatch/axiom-components';
import SpringChain from '../../utils/SpringChain';
import Layout from '../Layout/Layout';
import Link from '../Link/Link';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';

export default class ExpiredPassword extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;

    return (
      <Layout width="32rem">
        <SpringChain>
          <BrandwatchLogo spring-space="x8" />

          <Heading textSize="display1">
            <Strong>{ t('sorry') }</Strong>
          </Heading>
          <Heading
              textColor="subtle"
              textSize="headline">{ t('expired-password-explanation') }</Heading>
          <Paragraph textColor="subtle">
            <Link to="/change">
              { t('change-password-link') }
            </Link>
          </Paragraph>
        </SpringChain>
      </Layout>
    );
  }
}
