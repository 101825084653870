import { call, put, takeLatest } from 'redux-saga/effects';
import apiGenerateCodeKey from '../api/generateCodeKey';
import {
  generateCodeKeyFailed,
  generateCodeKeyRequested,
  generateCodeKeySuccess,
} from '../store/generateCodeKey';

export function* watchGenerateCodeKeyRequested() {
  yield takeLatest(generateCodeKeyRequested, generateCodeKey);
}

export function* generateCodeKey({ payload }) {
  try {
    const {interactionUid}  = payload;
    const response = yield call(apiGenerateCodeKey, {interactionUid});
    yield put(generateCodeKeySuccess(response));
  } catch ({ message }) {
    yield put(generateCodeKeyFailed({ message }));
  }
}
