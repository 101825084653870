import handleApiResponse from '../utils/handleApiResponse';

export default (username) =>
  fetch(`${window.appConfig.BW_DOMAIN}/user/sendResetPasswordEmail`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ username }),
  }).then(handleApiResponse);
