import { createAction, handleActions } from 'redux-actions';

const initialState = {
  errorMessage: null,
  hasFailed: false,
  isSubmitting: false,
};

export const signUpRequested = createAction('SIGN_UP_REQUESTED', null,
  ({ email }) => ({ mixpanel: { eventName: 'signup:requested', props: { email } } }));

export const signUpFailed = createAction('SIGN_UP_FAILED', null,
  ({ message }) => ({ mixpanel: { eventName: 'signup:failed', props: { message } } }));

export const signUpSuccess = createAction('SIGN_UP_SUCCESS', null,
  () => ({ mixpanel: { eventName: 'signup:success' } }));

export const signUpResendRequested = createAction('SIGN_UP_RESEND_REQUESTED', null,
  ({ email }) => ({ mixpanel: { eventName: 'signup_resend:requested', props: { email } } }));

export default handleActions({
  [signUpRequested]: (state, { payload }) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    isSubmitting: true,
    email: payload.email,
    password: payload.password,
  }),
  [signUpFailed]: (state, { payload }) => ({
    ...state,
    errorMessage: payload.message,
    hasFailed: true,
    isSubmitting: false,
  }),
  [signUpSuccess]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    isSubmitting: false,
  }),
}, initialState);
