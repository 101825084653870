import PropTypes from 'prop-types';
import React from 'react';
import { Button, Heading, Link, Paragraph, Strong } from "@brandwatch/axiom-components";
import SpringChain from '../../../utils/SpringChain';
import BackgroundPage from '../../BackgroundPage/BackgroundPage';
import BrandwatchLogo from '../../BrandwatchLogo/BrandwatchLogo';

const theme = 'day';

const StatusLoginComplete = ({ oidcClientId, t }) => {
  const configRedirectUrl = window.appConfig.OIDC_CLIENT_REDIRECT_URLS;
  const oidcClientRedirectUrls = typeof configRedirectUrl === 'object' ? configRedirectUrl : JSON.parse(window.appConfig.OIDC_CLIENT_REDIRECT_URLS) || {};
  const redirectUrl = oidcClientRedirectUrls[oidcClientId];

  return (
    <BackgroundPage theme={ theme }>
      <div className="ax-status-message ax-text--align-center">
        <SpringChain>
          <BrandwatchLogo spring-space="x8" />
          <Heading textSize="display1">
            <Strong>{ t('login-complete-heading') }</Strong>
          </Heading>
          <Heading textSize="headline">{ t('login-complete-sub-heading') }</Heading>
          <Paragraph>{ t('login-complete-text') }</Paragraph>
          <Link href={ redirectUrl || window.appConfig.DEFAULT_REDIRECT_URL }>
            <Button size="large">{ t('login-complete-redirect-button') }</Button>
          </Link>
        </SpringChain>
      </div>
    </BackgroundPage>
  )
}

StatusLoginComplete.propTypes = {
  oidcClientId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default StatusLoginComplete;
