import handleApiResponse from '../utils/handleApiResponse';

export default ({code, interactionUid}) =>
  fetch(`/oidc/interaction/${interactionUid}/2fa`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({code})
    }).then(handleApiResponse);
