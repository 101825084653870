/* global require */

import { compose, createStore, applyMiddleware } from 'redux';
import { routerMiddleware as createRouterMiddleware, push } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import sagas from '../sagas/root';
import reducers from './root';

export default (history) => {
  const sagaMiddleware = createSagaMiddleware();
  const routerMiddleware = createRouterMiddleware(history);
  const middlewares = [sagaMiddleware, routerMiddleware];
  middlewares.push(createSagaMiddleware());

  if (typeof document !== 'undefined' && window.appConfig.MIXPANEL_TOKEN) {
    middlewares.push(require('remimi').default(window.appConfig.MIXPANEL_TOKEN, {
      config: {
        disable_persistence: true,
      },
      uniqueIdSelector: () => null,
      personSelector: () => {},
    }));
  }

  const finalCompose = window.appConfig.environment === 'production' ? compose : composeWithDevTools;

  const store = createStore(
    reducers(history),
    finalCompose(
      applyMiddleware(...middlewares)
    ),
  );

  sagaMiddleware.run(sagas, store.getState);

  // Allows the debugging of transitioning to a route
  if (window && window.appConfig.environment !== 'production') {
    window.debugRoute = (route) => {
      store.dispatch(push(route));
    };
  }

  return store;
};
