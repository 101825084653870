import { call, put, takeLatest } from 'redux-saga/effects';
import apiEnable2fa from '../api/enable2fa';
import { enable2faFailed, enable2faRequested, enable2faSuccess } from '../store/enable2fa';

export function* watchEnable2faRequested() {
  yield takeLatest(enable2faRequested, enable2fa);
}

export function* enable2fa({ payload }) {
  try {
    const { secretKey, interactionUid } = payload;
    const response = yield call(apiEnable2fa, {secretKey, interactionUid});
    yield put(enable2faSuccess(response));
  } catch ({ message }) {
    yield put(enable2faFailed({ message }));
  }
}
