import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';

import { Spring, animated, config } from 'react-spring/renderprops';

const ANIMATION_DELAY = 100;
const LOGO_DELAY = 150;

export default class SpringChain extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const {
      children,
    } = this.props;

    return (
      <Fragment>
        {
          React.Children.map(children, (item, index) => (
            <Spring
                native
                key={ index }
                config={ {
                  ...config.molasses,
                  delay: ANIMATION_DELAY * index + LOGO_DELAY,
                } }
                from={ { opacity: 0, translateY: -10 } }
                to={ { opacity: 1, translateY: 0 } }>
              {
                ({ opacity, translateY }) => {
                  return (
                    <animated.div
                        className={ `spring-chain ax-space--${(item && item.props['spring-space']) || 'x4'}` }
                        style={ {
                          opacity,
                          transform: translateY.interpolate(y => `translate3d(0, ${y}px, 0)`),
                        } }>
                      { item }
                    </animated.div>
                  );
                }
              }
            </Spring>
          ))
        }
      </Fragment>
    );
  }
}
