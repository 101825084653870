import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ButtonGroup,
  ButtonIcon,
  Form,
  Heading,
  Paragraph,
  ProgressButton,
  Strong,
  Grid,
  GridCell,
  TextInput,
} from '@brandwatch/axiom-components';
import {
  ConfirmPasswordInput,
  NewPasswordInput,
} from '@brandwatch/axiom-composites';
import SpringChain from '../../utils/SpringChain';
import Layout from '../Layout/Layout';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';
import atIds from '../../../at_ids';
import getUrlParams from '../../utils/getUrlParams';

export default class SignUp extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    hasFailed: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    const searchParams = getUrlParams();

    this.state = {
      password: '',
      confirmPassword: '',
      firstName: searchParams.firstName,
      lastName: searchParams.lastName,
      email: searchParams.email,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e, validationError) {
    const { onSubmit } = this.props;
    const { password, firstName, lastName } = this.state;
    const searchParams = getUrlParams();

    e.preventDefault();
    this.setState({ validationError });

    if (!validationError) {
      onSubmit({
        email: searchParams.email,
        token: searchParams.token,
        password,
        firstName,
        lastName,
      });
    }
  }

  render() {
    const { errorMessage, hasFailed, isSubmitting, t } = this.props;
    const { firstName, lastName, email, password, confirmPassword, validationError } = this.state;

    return (
      <Layout horizontalAlign="center" width="28rem">

        <Form
            onSubmit={ this.handleSubmit }
            requiredError={ t('validation-sign-up-all-fields') }>
          <SpringChain>
            <BrandwatchLogo spring-space="x8" />

            <Heading textSize="display1">
              <Strong>{ t('sign-up-title') }</Strong>
            </Heading>
            <Heading textColor="subtle" textSize="headtitle">
              { t('sign-up-subtitle') }
            </Heading>
            <Grid>
              <GridCell>
                <TextInput
                    label={ t('field-name-first-label') }
                    onChange={ e => this.setState({ firstName: e.target.value }) }
                    size="large"
                    required
                    value={ firstName } />
              </GridCell>
              <GridCell>
                <TextInput
                    label={ t('field-name-last-label') }
                    onChange={ e => this.setState({ lastName: e.target.value }) }
                    size="large"
                    required
                    value={ lastName } />
              </GridCell>
            </Grid>

            <TextInput
                disabled
                label={ t('field-email-label') }
                placeholder={ t('field-email-label') }
                onChange={ () => {} }
                size="large"
                value={ email } />

            <NewPasswordInput
                data-ah-at={ atIds.SignUp.password }
                onChange={ (e) => this.setState({ password: e.target.value }) }
                size="large"
                required
                value={ password } />

            <ConfirmPasswordInput
                data-ah-at={ atIds.SignUp.confirmPassword }
                onChange={ (e) => this.setState({ confirmPassword: e.target.value }) }
                passwordValue={ password }
                size="large"
                value={ confirmPassword } />

            <ButtonGroup>
              <ProgressButton
                  data-ah-at={ atIds.SignUp.submit }
                  disabled={ isSubmitting }
                  full="small"
                  isInProgress={ isSubmitting }
                  size="large"
                  type="submit">
                { t('sign-up') } <ButtonIcon name="chevron-right" /></ProgressButton>
            </ButtonGroup>
          </SpringChain>

          { (validationError || hasFailed) && (
            <Paragraph
                space="x4"
                textCenter
                textColor="error"
                textLeft="small">
              <Strong data-ah-at={ atIds.SignUp.error }>
                { validationError || errorMessage || t('generic-error-fallback') }
              </Strong>
            </Paragraph>
          ) }
        </Form>
      </Layout>
    );
  }
}
