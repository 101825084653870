module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!DOCTYPE html>\n<html class="ax-theme--day">\n  <head>\n    <meta charset="UTF-8">\n    <meta content="width=device-width, minimum-scale=1.0, initial-scale=1.0, user-scalable=no" name="viewport">\n    <title>Brandwatch | Sign in</title>\n    <link href="{{.Base}}/assets/favicon.ico?v=2" rel="shortcut icon">\n    <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700" rel="stylesheet">\n    ';
 if (!htmlWebpackPlugin.options.developentMode) { ;
__p += '\n      <link href="{{.Base}}/' +
((__t = ( htmlWebpackPlugin.files.css )) == null ? '' : __t) +
'" rel="stylesheet">\n    ';
 } ;
__p += '\n  </head>\n  <body>\n    <script>window.baseName = "{{.Base}}/";</script>\n    <script>window.appConfig = {{.Config}};</script>\n    <div class="ah-app-container" id="root">\n      ';
 if (htmlWebpackPlugin.options.html) { ;
__p += '\n        ' +
((__t = ( htmlWebpackPlugin.options.html )) == null ? '' : __t) +
'\n      ';
 } ;
__p += '\n    </div>\n    ';
 if (!htmlWebpackPlugin.options.developentMode) { ;
__p += '\n      <script src="{{.Base}}/' +
((__t = ( htmlWebpackPlugin.files.js )) == null ? '' : __t) +
'" type="application/javascript"></script>\n    ';
 } ;
__p += '\n  </body>\n</html>\n';

}
return __p
}