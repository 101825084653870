import { BroadcastChannel } from 'broadcast-channel';

const channel = new BroadcastChannel('loginComplete', { webWorkerSupport: false });

export const broadcastLoginComplete = () => {
  channel.postMessage({ loggedIn: true });
};

export const listenForLoginComplete = history => {
  channel.onmessage = ({ loggedIn }) => loggedIn && history.push('/loginComplete');
}
