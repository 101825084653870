import handleApiResponse from '../utils/handleApiResponse';
import apiGetToken from './getToken';

export default ({ email, password, firstName, lastName }) => apiGetToken({ email, password })
  .then(({ access_token }) => {
    return fetch(`${window.appConfig.BW_DOMAIN}/user`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`,
      }),
      body: JSON.stringify({
        firstName,
        lastName,
      }),
    }).then(handleApiResponse);
  });
