import { createAction, handleActions } from 'redux-actions';

const initialState = {
  hasFailed: false,
  interactionUid: null,
  isSubmitting: false,
  loginHint: null,
  response: null,
};

export const loginRequested = createAction('LOGIN_REQUESTED', null,
  ({ username }) => ({ mixpanel: { eventName: 'login:requested', props: { username } } }));

export const loginFailed = createAction('LOGIN_FAILED', null,
  (message) => ({ mixpanel: { eventName: 'login:failed', props: { message } } }));

export const loginSuccess = createAction('LOGIN_SUCCESS', null,
  ({ username }) => ({ mixpanel: { eventName: 'login:requested', props: { username } } }));

export const setInteractionUid = createAction('SET_INTERACTION_UID');

export const setLoginHint = createAction('SET_LOGIN_HINT');

export const setOidcClientId = createAction('SET_OIDC_CLIENT_ID');

export const clearLoginErrors = createAction('CLEAR_LOGIN_ERRORS');

export const interactionUidSelector = state => state.login.interactionUid;
export const loginHintSelector = state => state.login.loginHint;

export default handleActions({
  [loginRequested]: (state) => ({
    ...state,
    hasFailed: false,
    isSubmitting: true,
  }),
  [loginFailed]: (state, { payload }) => ({
    ...state,
    hasFailed: true,
    isSubmitting: false,
    response: { ...payload },
  }),
  [loginSuccess]: (state, { payload }) => ({
    ...state,
    hasFailed: false,
    response: { ...payload },
  }),
  [setInteractionUid]: (state, { payload }) => ({
    ...state,
    interactionUid: payload.interactionUid,
  }),
  [setLoginHint]: (state, { payload }) => ({
    ...state,
    loginHint: payload.loginHint,
  }),
  [setOidcClientId]: (state, { payload }) => ({
    ...state,
    oidcClientId: payload.oidcClientId,
  }),
  [clearLoginErrors]: (state) => ({
    ...state,
    hasFailed: false,
  }),
}, initialState);
