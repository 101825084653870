export const getSuccessRedirectUrl = returnTo => returnTo || window.appConfig.DEFAULT_REDIRECT_URL;

export const getInteractionRestartRedirectUrl = () => window.appConfig.OIDC_DEFAULT_REDIRECT_URL

export const getRedirectUrlWithInteraction = (url, interactionUid) => {
  const params = new URLSearchParams();

  if (interactionUid) {
    params.append('interactionUid', interactionUid);
  }

  const paramsString = params.toString();
  const hasParams = paramsString !== '';
  const searchString = hasParams ? `?${paramsString}` : '';

  return `${url}${searchString}`;
}
