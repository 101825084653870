import { INTERACTION_EXPIRED } from "../constants/apiErrorCodes";

class ApiRequestFailedError extends Error {
  constructor(message = '', code = null) {
    super(message);
    this.name = 'ApiRequestFailedError';
    this.message = (code > 499 && code < 600) ? '' : message;
    this.code = code;

    console.error(`AUTH API ERROR: ${code} ${message}`); // eslint-disable-line no-console
  }
}

const errorCodeFromName = name => {
  switch(name) {
    case 'SessionNotFound':
      return INTERACTION_EXPIRED
  }
}

export default (response) => {
  if (response.ok) {
    return response.json().catch(() => {});
  }

  return response.json()
  .then((data) => {
    const { error, error_description, errors, name } = data;

    if (error) {
      // Certain OIDC errors need special handling such as redirects
      if (name) {
        const code = errorCodeFromName(name);

        if (code) {
          return { error, code: errorCodeFromName(name)};
        }
      }

      throw new ApiRequestFailedError(error_description, error);
    }

    if (errors && errors.length) {
      let code;
      const message = errors.map(({ message }) => message).join('. ');

      if (errors[0].code) {
        code = errors[0].code;
      }

      throw new ApiRequestFailedError(message, code);
    }

    throw new ApiRequestFailedError(response.statusText, response.status);

  }, () => {
    // Note: Do not catch! This handles errors when converting to JSON only
    throw new ApiRequestFailedError(response.statusText, response.status);
  });
};
