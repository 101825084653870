import { createAction, handleActions } from 'redux-actions';

const initialState = {
  errorMessage: null,
  hasFailed: false,
  isSubmitting: false,
  response: null,
};

export const verifyCodeRequested = createAction('VERIFY_CODE_REQUESTED', null,
  () => ({ mixpanel: { eventName: 'verify_code:requested' } }));

export const verifyCodeFailed = createAction('VERIFY_CODE_FAILED', null,
  () => ({ mixpanel: { eventName: 'verify_code:failed' } }));

export const verifyCodeSuccess = createAction('VERIFY_CODE_SUCCESS', null,
  () => ({ mixpanel: { eventName: 'verify_code:success' } }));

export default handleActions({
  [verifyCodeRequested]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    isSubmitting: true,
  }),
  [verifyCodeFailed]: (state, { payload }) => ({
    ...state,
    errorMessage: payload.message,
    hasFailed: true,
    isSubmitting: false,
  }),
  [verifyCodeSuccess]: (state, { payload }) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    response: { ...payload },
  }),
}, initialState);
