import { connect } from 'react-redux';

import BackgroundSvg from './BackgroundSvg';

const mapStateToProps = (state) => {
  return {
    ...state.app
  };
};

export default connect(mapStateToProps)(BackgroundSvg);
