import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ButtonGroup,
  ButtonIcon,
  Form,
  Grid,
  GridCell,
  Heading,
  Paragraph,
  ProgressButton,
  Strong,
  TextInput,
} from '@brandwatch/axiom-components';
import Layout from '../Layout/Layout';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';
import axiomAtIds from '@brandwatch/axiom-automation-testing/ids';
import SpringChain from '../../utils/SpringChain';
import {getSuccessRedirectUrl, getInteractionRestartRedirectUrl} from '../../utils/getRedirectUrl';
import { broadcastLoginComplete } from '../../utils/broadcastLoginComplete';
import {INTERACTION_EXPIRED} from '../../constants/apiErrorCodes';

export default class TwoFactor extends Component {
  static propTypes = {
    onSendCode: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    verify_hasFailed: PropTypes.bool.isRequired,
    verify_isSubmitting: PropTypes.bool.isRequired,
    verify_response: PropTypes.object,
    interactionUid: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendCode = this.sendCode.bind(this);
    this.state = {
      code: '',
    };

    if (!this.props.interactionUid) {
      console.warn('No interaction. Restarting login flow.');

      return window.location.replace(
        getInteractionRestartRedirectUrl()
      );
    }
  }

  componentDidUpdate() {
    const { verify_response } = this.props;

    if (verify_response) {
      if (verify_response.code) {
        if (verify_response.code === INTERACTION_EXPIRED) {
          console.warn('Interaction expired. Restarting login flow.');

          return window.location.replace(
            getInteractionRestartRedirectUrl()
          );
        }
      }

      broadcastLoginComplete();

      window.location.replace(
        getSuccessRedirectUrl(verify_response.returnTo)
      );
    }
  }

  handleSubmit(event, validationError) {
    const { onSubmit, interactionUid } = this.props;
    const { code } = this.state;

    event.preventDefault();
    this.setState({ validationError });

    if (!validationError) {
      onSubmit({ code, interactionUid });
    }
  }

  sendCode(event) {
    const { onSendCode } = this.props;

    event.preventDefault();
    event.stopPropagation();

    onSendCode();
  }

  render() {
    const {
      code,
      validationError,
    } = this.state;

    const {
      verify_hasFailed,
      verify_isSubmitting,
      t,
    } = this.props;

    return (
      <Layout width="24.5rem">

        <Form
            onSubmit={ this.handleSubmit }
            requiredError={ t('validation-no-code') }>

          <SpringChain>
            <BrandwatchLogo spring-space="x8" />

            <Heading textSize="display1">
              <Strong>{ t('2fa-title') }</Strong>
            </Heading>

            <TextInput
                data-ax-at={ axiomAtIds.Login.password }
                onChange={ e => this.setState({ code: e.target.value }) }
                placeholder={ t('2fa-code-placeholder') }
                required
                size="large"
                type="text"
                value={ code } />

            <Grid verticalAlign="middle">
              <GridCell shrink>
                <ButtonGroup>
                  <ProgressButton
                      data-ax-at={ axiomAtIds.Login.submit }
                      disabled={ verify_isSubmitting }
                      full="small"
                      isInProgress={ verify_isSubmitting }
                      size="large"
                      type="submit">{ t('verify-button') }  <ButtonIcon name="chevron-right" />
                  </ProgressButton>
                </ButtonGroup>
              </GridCell>
            </Grid>
          </SpringChain>

          { (verify_hasFailed || validationError) && (
            <Paragraph
                space="x4"
                textCenter
                textColor="error"
                textLeft="small">
              <Strong data-ax-at={ axiomAtIds.Login.error }>
                { validationError || t('code-error-fallback') }
              </Strong>
            </Paragraph>
          ) }
        </Form>
      </Layout>
    );
  }
}
