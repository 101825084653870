import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Heading, Link, Paragraph, Strong } from '@brandwatch/axiom-components';
import SpringChain from '../../utils/SpringChain';
import Layout from '../Layout/Layout';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';

export default class RateLimited extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;

    return (
      <Layout width="32rem">
        <SpringChain>
          <BrandwatchLogo spring-space="x8" />

          <Heading space="x4" textSize="display1">
            <Strong>{ t('sorry') }</Strong>
          </Heading>
          <Heading
              space="x4"
              textColor="subtle"
              textSize="headline">{ t('rate-limited-explanation') }</Heading>
          <Paragraph textColor="subtle">
            <Link href="mailto:support@brandwatch.com">{ t('contact-support') }</Link>
          </Paragraph>
        </SpringChain>
      </Layout>
    );
  }
}
