import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ButtonGroup,
  ButtonIcon,
  Form,
  Heading,
  Paragraph,
  ProgressButton,
  Strong,
} from '@brandwatch/axiom-components';
import {
  ConfirmPasswordInput,
  NewPasswordInput,
} from '@brandwatch/axiom-composites';
import SpringChain from '../../utils/SpringChain';
import Layout from '../Layout/Layout';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';
import atIds from '../../../at_ids';
import getUrlParams from '../../utils/getUrlParams';

export default class ResetPassword extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    hasFailed: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      password: '',
      confirmPassword: '',
    };
  }

  handleSubmit(e, validationError) {
    const { onSubmit } = this.props;
    const { password } = this.state;
    const searchParams = getUrlParams();

    e.preventDefault();
    this.setState({ validationError });

    if (!validationError) {
      onSubmit({
        password,
        email: searchParams.email,
        token: searchParams.token,
      });
    }
  }

  render() {
    const { errorMessage, hasFailed, isSubmitting, t } = this.props;
    const { password, confirmPassword, validationError } = this.state;

    return (
      <Layout width="28rem">
        <Form
            onSubmit={ this.handleSubmit }
            requiredError={ t('validation-no-new-password') }>
          <SpringChain>
            <BrandwatchLogo spring-space="x8" />

            <Heading textSize="display1">
              <Strong>{ t('reset-password') }</Strong>
            </Heading>

            <NewPasswordInput
                data-ah-at={ atIds.ResetPassword.password }
                onChange={ (e) => this.setState({ password: e.target.value }) }
                required
                size="large"
                value={ password } />

            <ConfirmPasswordInput
                data-ah-at={ atIds.ResetPassword.confirmPassword }
                onChange={ (e) => this.setState({ confirmPassword: e.target.value }) }
                passwordValue={ password }
                size="large"
                value={ confirmPassword } />

            <ButtonGroup>
              <ProgressButton
                  data-ah-at={ atIds.ResetPassword.submit }
                  disabled={ isSubmitting }
                  full="small"
                  isInProgress={ isSubmitting }
                  size="large"
                  type="submit">
                { t('reset') } <ButtonIcon name="chevron-right" />
              </ProgressButton>
            </ButtonGroup>
          </SpringChain>

          { (validationError || hasFailed) && (
            <Paragraph
                space="x4"
                textCenter
                textColor="error"
                textLeft="small">
              <Strong data-ah-at={ atIds.ResetPassword.error }>
                { validationError || errorMessage || t('generic-error-fallback') }
              </Strong>
            </Paragraph>
          ) }

        </Form>
      </Layout>
    );
  }
}
