import { createAction, handleActions } from 'redux-actions';

const initialState = {
  errorMessage: null,
  hasFailed: false,
  isSubmitting: false,
  response: null,
};

export const enable2faRequested = createAction('ENABLE_2FA_REQUESTED', null,
  () => ({ mixpanel: { eventName: 'enable_2fa:requested' } }));

export const enable2faFailed = createAction('ENABLE_2FA_FAILED', null,
  () => ({ mixpanel: { eventName: 'enable_2fa:failed' } }));

export const enable2faSuccess = createAction('ENABLE_2FA_SUCCESS', null,
  () => ({ mixpanel: { eventName: 'enable_2fa:success' } }));

export default handleActions({
  [enable2faRequested]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    isSubmitting: true,
  }),
  [enable2faFailed]: (state, { payload }) => ({
    ...state,
    errorMessage: payload.message,
    hasFailed: true,
    isSubmitting: false,
  }),
  [enable2faSuccess]: (state, { payload }) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    response: { ...payload },
  }),
}, initialState);
