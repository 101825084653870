import { createAction, handleActions } from 'redux-actions';

const initialState = {
  errorMessage: null,
  hasFailed: false,
  isSubmitting: false,
  response: null,
};

export const sendCodeRequested = createAction('SEND_CODE_REQUESTED', null,
  () => ({ mixpanel: { eventName: 'send_code:requested' } }));

export const sendCodeFailed = createAction('SEND_CODE_FAILED', null,
  () => ({ mixpanel: { eventName: 'send_code:failed' } }));

export const sendCodeSuccess = createAction('SEND_CODE_SUCCESS', null,
  () => ({ mixpanel: { eventName: 'send_code:success' } }));

export default handleActions({
  [sendCodeRequested]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    hasSucceeded: false,
    isSubmitting: true,
  }),
  [sendCodeFailed]: (state, { payload }) => ({
    ...state,
    errorMessage: payload.message,
    hasFailed: true,
    hasSucceeded: false,
    isSubmitting: false,
  }),
  [sendCodeSuccess]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    hasSucceeded: true,
    isSubmitting: false,
  }),
}, initialState);
