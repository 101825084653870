import { push } from 'connected-react-router';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import apiForgot from '../api/forgot';
import apiSignUp from '../api/signUp';
import apiSetUserDetails from '../api/setUserDetails';
import { notificationsAddNotification } from '../store/notifications';
import { TOKEN_EXPIRY_ERROR_CODE } from '../constants/apiErrorCodes';
import {
  signUpFailed,
  signUpRequested,
  signUpSuccess,
  signUpResendRequested,
} from '../store/signUp';

export function* watchSignUpRequested() {
  yield takeEvery(signUpRequested, signUp);
}

export function* watchSignUpResendRequested() {
  yield takeEvery(signUpResendRequested, signUpResend);
}

export function* signUp({ payload }) {
  const { email, password, token, firstName, lastName } = payload;
  try {
    yield call(apiSignUp, { email, password, token });
    yield call(apiSetUserDetails, { email, password, firstName, lastName });
    yield put(signUpSuccess());
    yield put(push('/sign-up/confirmation'));
  } catch ({ code, message }) {
    if (code === TOKEN_EXPIRY_ERROR_CODE) {
      yield put(push(`/sign-up/expired?email=${ encodeURIComponent(email) }`));
    }
    yield put(signUpFailed({ message }));
  }
}

export function* signUpResend({ payload }) {
  try {
    const { email } = payload;
    yield call(apiForgot, email);
    yield put(notificationsAddNotification({
      type: 'success',
      message: 'sign-up-resend-successful',
    }));
    // Wait 3s before redirecting to allow notification to show.
    yield delay(3000);
    yield put(push('/'));
  } catch ({ message }) {
    yield put(notificationsAddNotification({
      type: 'error',
      message,
    }));
  }
}
