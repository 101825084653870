import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { loginRequested } from '../../store/login';
import { updateUser } from '../../store/user';
import Login from './Login';

const mapStateToProps = ({ login }) => ({
  hasFailed: login.hasFailed,
  isSubmitting: login.isSubmitting,
  loginHint: login.loginHint,
  response: login.response,
  interactionUid: login.interactionUid,
});

const mapDispatchToProps = {
  onSubmit: loginRequested,
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Login));
