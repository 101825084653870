import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ResetPassword from './ResetPassword';
import { resetRequested } from '../../store/resetPassword';

const mapStateToProps = ({ resetPassword }) => ({
  errorMessage: resetPassword.errorMessage,
  hasFailed: resetPassword.hasFailed,
  isSubmitting: resetPassword.isSubmitting,
});

const mapDispatchToProps = {
  onSubmit: resetRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ResetPassword));
