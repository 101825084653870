import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app';
import changePassword from './changePassword';
import enable2fa from './enable2fa';
import forgotPassword from './forgotPassword';
import generateCodeKey from './generateCodeKey';
import login from './login';
import notifications from './notifications';
import resetPassword from './resetPassword';
import sendCode from './sendCode';
import signUp from './signUp';
import user from './user';
import verifyCode from './verifyCode';

export default function buildRootReducer(history) {
  const appReducer = combineReducers({
    router: connectRouter(history),
    app,
    changePassword,
    enable2fa,
    forgotPassword,
    generateCodeKey,
    login,
    notifications,
    resetPassword,
    sendCode,
    signUp,
    user,
    verifyCode,
  });

  return function rootReducer(state, action) {
    if (['CHANGE_SUCCESS'].includes(action.type)) {
      state = undefined;
    }

    return appReducer(state, action);
  };
}
