import React from 'react';
import { Base, Icon } from "@brandwatch/axiom-components";
import './BrandwatchLogo.css';

const BrandwatchLogo = () => {
  return (
    <div className="ah-logo-page__logo_container">
      <Base className="ah-logo-page__logo">
        <Icon name={'brandwatch'} size="2.5rem" />
      </Base>
    </div>
  )
}

export default BrandwatchLogo;
