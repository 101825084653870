import handleApiResponse from '../utils/handleApiResponse';

export default (username, oldPassword, newPassword) =>
  fetch(`${window.appConfig.BW_DOMAIN}/user/changePassword`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ username, newPassword, oldPassword }),
  }).then(handleApiResponse);
