import { createAction, handleActions } from 'redux-actions';

const initialState = {
  hasFailed: false,
  isSubmitting: false,
};

export const changeRequested = createAction('CHANGE_REQUESTED', null,
  ({ email }) => ({ mixpanel: { eventName: 'change_password:requested', props: { email } } })
);

export const changeFailed = createAction('CHANGE_FAILED', null,
  ({ message }) => ({ mixpanel: { eventName: 'change_password:failed', props: { message } } })
);

export const changeSuccess = createAction('CHANGE_SUCCESS', null,
  () => ({ mixpanel: { eventName: 'change_password:success' } })
);

export default handleActions({
  [changeRequested]: (state) => ({
    ...state,
    hasFailed: false,
    isSubmitting: true,
  }),
  [changeFailed]: (state, { payload }) => ({
    ...state,
    hasFailed: true,
    isSubmitting: false,
    response: { ...payload },
  }),
  [changeSuccess]: (state) => ({
    ...state,
    hasFailed: false,
    isSubmitting: false,
  }),
}, initialState);
