import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Status404 from '../StatusPages/Status404';
import StatusError from '../StatusPages/StatusError';
import StatusLoginComplete from '../StatusPages/StatusLoginComplete';
import App from '../App';

export default class Root extends Component {
  render() {
    return (
      <Switch>
        <Route component={ Status404 } exact path="/404" />
        <Route component={ StatusError } exact path="/error" />
        <Route component={ StatusLoginComplete } exact path="/loginComplete" />
        <Route component={ App } path="/" />
      </Switch>
    );
  }
}
