import { push } from 'connected-react-router';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import apiChange from '../api/change';
import { changeFailed, changeRequested, changeSuccess } from '../store/changePassword';
import { notificationsAddNotification } from '../store/notifications';

export function* watchChangeRequested() {
  yield takeEvery(changeRequested, change);
}

export function* change({ payload }) {
  try {
    const { username, oldPassword, newPassword } = payload;
    yield call(apiChange, username, oldPassword, newPassword);
    yield put(changeSuccess());
    yield put(notificationsAddNotification({
      type: 'success',
      message: 'successful-password-change',
    }));
    // Wait 3s before redirecting to allow notification to show.
    yield delay(3000);
    yield put(push('/'));
  } catch ({ message }) {
    yield put(changeFailed({ message }));
  }
}
