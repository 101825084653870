import { createAction, handleActions } from 'redux-actions';
import apps from '../constants/apps';
import getUrlParams from '../utils/getUrlParams';

const getAppConfig = product_code => {
  const defaultApp = apps.find(({ id }) => id === 'default');
  return apps.find(({ id }) => id === product_code) || defaultApp;
};

const { product_code } = getUrlParams();
const initialState = { ...getAppConfig(product_code) };

export const updateAppConfig = createAction('UPDATE_APP_CONFIG');

export default handleActions({
  [updateAppConfig]: (state, { payload: { productCode } }) => {
    return {
      ...state,
      ...getAppConfig(productCode),
    };
  },
}, initialState);
