import handleApiResponse from '../utils/handleApiResponse';

export default ({interactionUid, secretKey}) =>
fetch(`/oidc/interaction/${interactionUid}/2fa/setup/enable`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({secretKey}),
  }).then(handleApiResponse);
