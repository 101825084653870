import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Base } from '@brandwatch/axiom-components';
import './Layout.css';

export default class Layout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    horizontalAlign: PropTypes.oneOf(['left', 'center']),
    width: PropTypes.string.isRequired,
  };

  static defaultProps = {
    horizontalAlign: 'left',
  }

  render() {
    const { children, horizontalAlign, width, ...rest } = this.props;
    const style = { width };

    return (
      <Base { ...rest } className={ `ah-layout ah-layout--${horizontalAlign}` } style={ style }>
        { children }
      </Base>
    );
  }
}
