import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { signUpResendRequested } from '../../store/signUp';
import SignUpExpired from './SignUpExpired';

const mapDispatchToProps = {
  onRequestInvitation: signUpResendRequested,
};

export default connect(() => ({}), mapDispatchToProps)(translate()(SignUpExpired));
