import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ButtonGroup,
  ButtonIcon,
  Form,
  Grid,
  GridCell,
  Heading,
  Paragraph,
  ProgressButton,
  Strong,
} from '@brandwatch/axiom-components';
import Layout from '../Layout/Layout';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';
import axiomAtIds from '@brandwatch/axiom-automation-testing/ids';
import SpringChain from '../../utils/SpringChain';
import {getInteractionRestartRedirectUrl} from '../../utils/getRedirectUrl';

import QRCode from 'qrcode.react';

export default class TwoFactorSetup extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    generateCodeKey: PropTypes.func.isRequired,
    generateCodeKey_response: PropTypes.object,
    generateCodeKey_hasFailed: PropTypes.bool.isRequired,
    enable2fa_hasFailed: PropTypes.bool.isRequired,
    enable2fa_isSubmitting: PropTypes.bool.isRequired,
    enable2fa_response: PropTypes.object,
    history: PropTypes.object,
    interactionUid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      secretKey: '',
    };

    if (!this.props.interactionUid) {
      console.warn('No interaction. Restarting login flow.');

      return window.location.replace(
        getInteractionRestartRedirectUrl()
      );
    }
  }

  componentDidMount() {
    const {interactionUid} = this.props;
    this.props.generateCodeKey({interactionUid});
  }

  componentDidUpdate() {
    if (this.props.enable2fa_response) {
      return this.props.history.push('/multi-factor');
    }
  }

  handleSubmit(event, enable2faError) {
    const { onSubmit, interactionUid } = this.props;
    const secretKey = this.props.generateCodeKey_response
      && this.props.generateCodeKey_response.secretKey;

    event.preventDefault();
    this.setState({ enable2faError });

    if (!enable2faError) {
      onSubmit({ secretKey, interactionUid });
    }
  }

  render() {
    const {
      t,
      enable2fa_hasFailed,
      enable2fa_isSubmitting,
      generateCodeKey_hasFailed,
    } = this.props;

    const { enable2faError } = this.state;

    const otpAuthURL = this.props.generateCodeKey_response
      && this.props.generateCodeKey_response.otpAuthURL;

    const secretKey = this.props.generateCodeKey_response
      && this.props.generateCodeKey_response.secretKey;

    return (
      <Layout width="32rem">

        <Form
            onSubmit={ this.handleSubmit }
            requiredError={ t('validation-no-secret-key') }>

          <SpringChain>
            <BrandwatchLogo spring-space="x8" />

            <Heading textSize="display1">
              <Strong>{ t('2fa-setup-title') }</Strong>
            </Heading>

            <Paragraph>
              { t('2fa-scan-to-setup') }
            </Paragraph>

            <Paragraph>
              { t('2fa-google-or-1password') }
            </Paragraph>

            { (generateCodeKey_hasFailed) ? (
              <Paragraph
                  space="x4"
                  textCenter
                  textColor="error"
                  textLeft="small">
                <Strong data-ax-at={ axiomAtIds.Login.error }>
                  { enable2faError || t('2fa-generate-code-error') }<br/>
                  { t('contact-support') }
                </Strong>
              </Paragraph>
            ) : (
              (otpAuthURL)
                ? <QRCode
                    value={ otpAuthURL }
                    renderAs="svg"
                    bgColor="transparent"
                    level="L" />
                : <Paragraph>{ t('2fa-generating-qr-code') }</Paragraph>
            ) }

            <Grid verticalAlign="middle">
              <GridCell shrink>
                <ButtonGroup>
                  <ProgressButton
                      data-ax-at={ axiomAtIds.Login.submit }
                      disabled={ !secretKey || enable2fa_isSubmitting }
                      full="small"
                      isInProgress={ enable2fa_isSubmitting }
                      size="large"
                      type="submit">{ t('enable') } <ButtonIcon name="chevron-right" />
                  </ProgressButton>
                </ButtonGroup>
              </GridCell>
            </Grid>
          </SpringChain>

          { (enable2fa_hasFailed || enable2faError) && (
            <Paragraph
                space="x4"
                textCenter
                textColor="error"
                textLeft="small">
              <Strong data-ax-at={ axiomAtIds.Login.error }>
                { enable2faError || t('enable-2fa-error-fallback') }<br/>
                { t('contact-support') }
              </Strong>
            </Paragraph>
          ) }

        </Form>
      </Layout>
    );
  }
}
