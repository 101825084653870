import PropTypes from 'prop-types';
import React from 'react';
import { Heading, Paragraph, Strong, Button, ButtonIcon } from '@brandwatch/axiom-components';
import SpringChain from '../../utils/SpringChain';
import Layout from '../Layout/Layout';
import Link from '../Link/Link';
import BrandwatchLogo from '../BrandwatchLogo/BrandwatchLogo';

const SignUpConfirmation = ({ t }) => {
  return (
    <Layout width="32rem">
      <SpringChain>
        <BrandwatchLogo spring-space="x8" />

        <Heading textSize="display1">
          <Strong>{ t('sign-up-confirmation-title') }</Strong>
        </Heading>
        <Heading textColor="subtle" textSize="headline">
          { t('sign-up-confirmation-subtitle') }
        </Heading>
        <Paragraph textColor="subtle">
          <Link href={ window.appConfig.DEFAULT_REDIRECT_URL }>
            <Button size="large">{ t('login-button') } <ButtonIcon name="chevron-right" /></Button>
          </Link>
        </Paragraph>
      </SpringChain>
    </Layout>
  );
};

SignUpConfirmation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SignUpConfirmation;
