import { BroadcastChannel } from 'broadcast-channel';

const channel = new BroadcastChannel('interactionUid', {webWorkerSupport: false});

export const listenForInteraction = (dispatch) => {
  channel.onmessage = (message) => dispatch(message);
};

export const broadcastInteraction = ({interactionUid, client_id}) => {
  if (!interactionUid || !client_id) {
    return;
  }

  channel.postMessage({client_id, interactionUid});
};
