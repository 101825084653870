import handleApiResponse from '../utils/handleApiResponse';

export default (email, newPassword, token) =>
  fetch(`${window.appConfig.BW_DOMAIN}/user/resetPassword`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ email, newPassword, token }),
  }).then(handleApiResponse);
