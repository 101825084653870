import { createAction, handleActions } from 'redux-actions';

const initialState = {
  errorMessage: null,
  hasFailed: false,
  isSubmitting: false,
};

export const forgotRequested = createAction('FORGOT_REQUESTED', null,
  ({ email }) => ({ mixpanel: { eventName: 'forgot_password:requested', props: { email } } }));

export const forgotFailed = createAction('FORGOT_FAILED', null,
  ({ message }) => ({ mixpanel: { eventName: 'forgot_password:failed', props: { message } } }));

export const forgotSuccess = createAction('FORGOT_SUCCESS', null,
  () => ({ mixpanel: { eventName: 'forgot_password:success' } }));

export default handleActions({
  [forgotRequested]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    isSubmitting: true,
  }),
  [forgotFailed]: (state, { payload }) => ({
    ...state,
    errorMessage: payload.message,
    hasFailed: true,
    isSubmitting: false,
  }),
  [forgotSuccess]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    isSubmitting: false,
  }),
}, initialState);
