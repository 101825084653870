import { call, put, takeLatest } from 'redux-saga/effects';
import apiSendCode from '../api/sendCode';
import { sendCodeFailed, sendCodeRequested, sendCodeSuccess } from '../store/sendCode';
import { notificationsAddNotification } from '../store/notifications';

export function* watchSendCodeRequested() {
  yield takeLatest(sendCodeRequested, sendCode);
}

export function* sendCode() {
  try {
    const response = yield call(apiSendCode);
    yield put(sendCodeSuccess(response));
    yield put(notificationsAddNotification({
      type: 'success',
      message: 'successful-send-code-requested',
    }));
  } catch ({ message }) {
    yield put(sendCodeFailed({ message }));
    yield put(notificationsAddNotification({
      type: 'error',
      message: 'error-send-code-requested',
    }));
  }
}
