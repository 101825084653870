import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { Base } from '@brandwatch/axiom-components';
import BackgroundSvg from '../BackgroundSvg';
import { Spring, animated, config } from 'react-spring/renderprops';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';

import SpringChain from '../../utils/SpringChain';

import './BackgroundPage.css';

const FRAME_SRC = 'https://www.brandwatch.com/app/login-promo/';
const PAGES_WITH_FRAME = ['/', '/login', '/login/'];

class Login extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {

    // Disabled lines are to prevent spreading those props to Base (no-unused-vars react/prop-types)
    const {
      children,
      location,
      match, // eslint-disable-line
      history, // eslint-disable-line
      ...rest
    } = this.props;

    const hasIframe = PAGES_WITH_FRAME.includes(location.pathname);

    const classes = classnames('ah-background-page', { 'framed-page': hasIframe });

    return (
      <Base { ...rest }
          className={ classes }
          theme="day">

        <div className="master-layout">

          <div className="form-container">
            <Spring
                native
                config={ config.molasses }
                from={ { opacity: 0, translateY: -10 } }
                to={ { opacity: 1, translateY: 0 } }>
              {
                ({ opacity, translateY }) => {
                  return (
                    <animated.div
                        className="ah-background-page__body"
                        style={ {
                          opacity,
                          transform: translateY.interpolate(y => `translate3d(0, ${y}px, 0)`),
                        } }>
                      { children }
                    </animated.div>
                  );
                }
              }
            </Spring>
          </div>

          <Switch>
            <Route path={ PAGES_WITH_FRAME } exact render={ () => (
              <div className="iframe-container">
                <SpringChain>
                  <iframe className="marketing-iframe" src={ FRAME_SRC }></iframe>
                </SpringChain>
              </div>
            ) } />
          </Switch>

        </div>

        <div className="ah-background-page__background">
          <BackgroundSvg />
        </div>
      </Base>
    );
  }
}

export default withRouter(Login);
