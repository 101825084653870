import { createAction, handleActions } from 'redux-actions';

const initialState = {
  errorMessage: null,
  hasFailed: false,
  isSubmitting: false,
  response: null,
};

export const generateCodeKeyRequested = createAction('GENERATE_CODE_KEY_REQUESTED', null,
  () => ({ mixpanel: { eventName: 'generate_code:requested' } }));

export const generateCodeKeyFailed = createAction('GENERATE_CODE_KEY_FAILED', null,
  () => ({ mixpanel: { eventName: 'generate_code:failed' } }));

export const generateCodeKeySuccess = createAction('GENERATE_CODE_KEY_SUCCESS', null,
  () => ({ mixpanel: { eventName: 'generate_code:success' } }));

export default handleActions({
  [generateCodeKeyRequested]: (state) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    hasSucceeded: false,
    isSubmitting: true,
  }),
  [generateCodeKeyFailed]: (state, { payload }) => ({
    ...state,
    errorMessage: payload.message,
    hasFailed: true,
    hasSucceeded: false,
    isSubmitting: false,
  }),
  [generateCodeKeySuccess]: (state, { payload }) => ({
    ...state,
    errorMessage: null,
    hasFailed: false,
    hasSucceeded: true,
    isSubmitting: false,
    response: { ...payload },
  }),
}, initialState);
