import { connect } from 'react-redux';
import { loginRequested } from '../../store/login';
import App from './App';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onLogin: loginRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
